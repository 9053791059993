import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { CancelToken } from 'axios'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  HeartRequest,
  IGroupMasterFilter,
  IMasterExercisesFilter,
  IMasterGroupsFilter,
  ManagerCoursesUnallocatedGetRequest,
  ManagerMasterGroupsAllocateGetParams,
  ManagerMasterGroupsAllocateGetRequest,
  ManagerMasterGroupsGetRequest,
  ManagerMasterGroupsMasterGroupIdDeleteRequest,
  ManagerMasterGroupsMasterGroupIdGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersGetParams,
  ManagerMasterGroupsMasterGroupIdMastersGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdAllocatePostRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdAvailableGroupsGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesCompletedGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesFailedGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartCureExerciseIdPostRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartTakeExerciseIdPostRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsLessonIdGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterIdTransferNewMasterGroupIdPostRequest,
  ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetParams,
  ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetRequest,
  ManagerMasterGroupsMasterGroupIdMastersSelectGetParams,
  ManagerMasterGroupsMasterGroupIdMastersSelectGetRequest,
  ManagerMasterGroupsMasterGroupIdPostRequest,
  ManagerMasterGroupsMastersTransferPostRequest,
  ManagerMasterGroupsMastersUnallocatedGetRequest,
  ManagerMasterGroupsPostRequest, ManagerMasterGroupsUnallocatedRejectPostRequest,
  MasterEducationMasterGroupIdLessonsGetParams,
  MasterGroupCreateRequest,
  MasterGroupLargeResource,
  MasterGroupShortResourcePaginator,
  MasterGroupTransferMastersRequest,
  MasterGroupUpdateRequest,
  MasterLargeResource,
  MasterLessonLargeResource,
  MasterLessonShortResource,
  MasterMoveReasonRequest, NameValueChildrenCustomResource, RejectFromUnallocatedRequest,
  UserShortResourcePaginator,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с мастер-группами системы
 * - получение списка МГ
 * - просмотр МГ
 * - получение списка мастеров МГ
 * - просмотр мастера МГ
 * - восстановление жизни, перевод между группами мастера МГ
 * - получение списка уроков мастера МГ
 */

@Module({
  dynamic: true,
  name: 'ManagerGroups',
  namespaced: true,
  store,
})
class ManagerGroups extends VuexModule {
  // ---------------------------- Groups ---------------------------- >>
  // Filter
  groupsFilter: IMasterGroupsFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setGroupsFilter (payload: IMasterGroupsFilter) {
    this.groupsFilter = Object.assign({}, payload)
  }

  // Entities
  groups: MasterGroupShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setGroups (payload: MasterGroupShortResourcePaginator) {
    this.groups = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroups(cancelToken?: CancelToken) {
    const { data } = await ManagerMasterGroupsGetRequest(tableOptionsToParams(this.groupsFilter), { cancelToken })
    this.setGroups(data)
    return data
  }

  @Action({ rawError: true })
  async fetchGroupsForMaster (payload: { masterGroupId: number, masterId: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdAvailableGroupsGetRequest(payload.masterGroupId, payload.masterId)
    return data
  }

  // ---------------------------- Group ---------------------------- >>
  group: MasterGroupLargeResource | null = null

  @Mutation
  setGroup (payload: MasterGroupLargeResource) {
    this.group = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroup (groupID: number) {
    const { data } = await ManagerMasterGroupsMasterGroupIdGetRequest(groupID)
    this.setGroup(data)
    return data
  }

  // ---------------------------- Group manage ---------------------------- >>
  @Action({ rawError: true })
  async createGroup (payload: MasterGroupCreateRequest) {
    const { data } = await ManagerMasterGroupsPostRequest(payload)
    this.setGroup(data)
    return data
  }

  @Action({ rawError: true })
  async saveGroup (payload: { masterGroupID: number, params: MasterGroupUpdateRequest }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdPostRequest(payload.masterGroupID, payload.params)
    this.setGroup(data)
    return data
  }

  @Action({ rawError: true })
  async deleteGroup (groupID: number) {
    await ManagerMasterGroupsMasterGroupIdDeleteRequest(groupID)
  }

  // ---------------------------- Group masters ---------------------------- >>

  currentMyMasterGroupId: number | null = null
  groupMastersFilter: ManagerMasterGroupsMasterGroupIdMastersGetParams = {
    monthId: undefined,
    query: undefined,
  }
  groupMastersFilterMentors: IGroupMasterFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setCurrentMyMasterGroup(payload: number | null) {
    this.currentMyMasterGroupId = payload
  }

  @Mutation
  setGroupMastersFilter (payload: ManagerMasterGroupsMasterGroupIdMastersGetParams) {
    this.groupMastersFilter = payload
  }

  @Mutation
  setGroupMastersFilterMentors (payload: IGroupMasterFilter) {
    this.groupMastersFilterMentors = payload
  }

  @Action({ rawError: true })
  async fetchGroupMasters (payload: { masterGroupID: number, params?: ManagerMasterGroupsMasterGroupIdMastersGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersGetRequest(payload.masterGroupID, payload.params || this.groupMastersFilter)
    return data
  }

  @Action({ rawError: true })
  async fetchMasterGroupsSelect (payload: { masterGroupID: number, params?: ManagerMasterGroupsMasterGroupIdMastersGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersGetRequest(payload.masterGroupID, payload.params)
    return data
  }

  @Action({ rawError: true })
  async fetchGroupMastersMentors (masterGroupID: number) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersGetRequest(masterGroupID, tableOptionsToParams(this.groupMastersFilterMentors))
    return data
  }

  @Action({ rawError: true })
  async fetchGroupMastersSelect (payload: { masterGroupID: number, filter?: ManagerMasterGroupsMasterGroupIdMastersSelectGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersSelectGetRequest(payload.masterGroupID, payload.filter, { loading: false })
    return data
  }

  // ---------------------------- Group master ---------------------------- >>
  groupMaster: MasterLargeResource | null = null

  @Mutation
  setGroupMaster (payload: MasterLargeResource) {
    this.groupMaster = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroupMaster (payload: { masterGroupID: number, masterUUID: string, params?: ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterUuidGetRequest(payload.masterGroupID, payload.masterUUID, payload.params)
    this.setGroupMaster(data)
    return data
  }

  @Action({ rawError: true })
  async fetchGroupMasterExercises (payload: { masterGroupID: number, userId: number, filter: IMasterExercisesFilter }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesGetRequest(payload.masterGroupID, payload.userId, tableOptionsToParams(payload.filter))
    return data
  }

  // ---------------------------- Group master manage ---------------------------- >>
  @Action({ rawError: true })
  async cureGroupMaster (payload: { masterGroupID: number, masterID: number, exerciseID: number, body: HeartRequest, params?: any }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartCureExerciseIdPostRequest(payload.masterGroupID, payload.masterID, payload.exerciseID, payload.body, payload.params)
    this.setGroupMaster(data)
    return data
  }

  @Action({ rawError: true })
  async takeGroupMaster(payload: { masterGroupID: number, masterID: number, exerciseID: number, body: HeartRequest, params?: any }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdHeartTakeExerciseIdPostRequest(payload.masterGroupID, payload.masterID, payload.exerciseID, payload.body, payload.params)
    this.setGroupMaster(data)
    return data
  }

  @Action({ rawError: true })
  async fetchFailedHeart(payload: { masterGroupID: number, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesFailedGetRequest(payload.masterGroupID, payload.masterID)
    return data
  }

  @Action({ rawError: true })
  async fetchCompleteHeart(payload: { masterGroupID: number, masterID: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdExercisesCompletedGetRequest(payload.masterGroupID, payload.masterID)
    return data
  }

  @Action({ rawError: true })
  async allocateGroupMaster (payload: { masterGroupID: number, masterID: number, params: MasterMoveReasonRequest }) {
    await ManagerMasterGroupsMasterGroupIdMastersMasterIdAllocatePostRequest(payload.masterGroupID, payload.masterID, payload.params)
  }

  @Action({ rawError: true })
  async transferGroupMaster (payload: { masterGroupID: number, masterID: number, newMasterGroupID: number, params: MasterMoveReasonRequest }) {
    await ManagerMasterGroupsMasterGroupIdMastersMasterIdTransferNewMasterGroupIdPostRequest(payload.masterGroupID, payload.masterID, payload.newMasterGroupID, payload.params)
  }

  @Action({ rawError: true })
  async transferMastersToGroup (payload: MasterGroupTransferMastersRequest) {
    const { data } = await ManagerMasterGroupsMastersTransferPostRequest(payload)
    return data
  }

  // ---------------------------- Group master lessons ---------------------------- >>
  groupMasterLessons: MasterLessonShortResource[] = []

  @Mutation
  setGroupMasterLessons (payload: MasterLessonShortResource[]) {
    this.groupMasterLessons = [...payload]
  }

  @Action({ rawError: true })
  async fetchGroupMasterLessons (payload: { masterGroupID: number, masterID: number, params: MasterEducationMasterGroupIdLessonsGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsGetRequest(payload.masterGroupID, payload.masterID, payload.params)
    this.setGroupMasterLessons(data)
    return data
  }

  // ---------------------------- Group master lesson ---------------------------- >>
  groupMasterLesson: MasterLessonLargeResource | null = null

  @Mutation
  setGroupMasterLesson (payload: MasterLessonLargeResource) {
    this.groupMasterLesson = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchGroupMasterLesson (payload: { masterGroupID: number, masterID: number, lessonId: number }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdMastersMasterIdLessonsLessonIdGetRequest(payload.masterGroupID, payload.masterID, payload.lessonId)
    this.setGroupMasterLesson(data)
    return data
  }

  // ---------------------------- Unallocated masters ---------------------------- >>
  // Filter
  unallocatedMastersFilter: IMasterGroupsFilter = GET_DEFAULT_TABLE_FILTER()

  @Mutation
  setUnallocatedMastersFilter (payload: IMasterGroupsFilter) {
    this.unallocatedMastersFilter = Object.assign({}, payload)
  }

  // Entities
  unallocatedMasters: UserShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  unallocatedCourses: NameValueChildrenCustomResource[]= []

  @Mutation
  setUnallocatedMasters (payload: UserShortResourcePaginator) {
    this.unallocatedMasters = Object.assign({}, payload)
  }

  @Mutation
  setUnallocatedCourses(payload: NameValueChildrenCustomResource[]) {
    this.unallocatedCourses = payload
  }

  @Action({ rawError: true })
  async fetchUnallocatedMasters () {
    const { data } = await ManagerMasterGroupsMastersUnallocatedGetRequest(tableOptionsToParams(this.unallocatedMastersFilter))
    this.setUnallocatedMasters(data)
    return data
  }

  @Action({ rawError: true })
  async fetchAllocateMasters(payload: ManagerMasterGroupsAllocateGetParams) {
    const { data } = await ManagerMasterGroupsAllocateGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async fetchManagerCoursesUnallocated() {
    const { data } = await ManagerCoursesUnallocatedGetRequest(
      { loading: false },
    )
    this.setUnallocatedCourses(data)
    return data
  }

  @Action({ rawError: true })
  async fetchUnallocatedReject(payload: RejectFromUnallocatedRequest) {
    const { data } = await ManagerMasterGroupsUnallocatedRejectPostRequest(payload)
    return data
  }
}

const ManagerGroupsModule = getModule(ManagerGroups)

export default ManagerGroupsModule
